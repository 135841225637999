import React from "react";

import {LinkIcon} from "svg/LinkIcon";
import profile1 from "image/XMLID_1_.png";
import profile2 from "image/XMLID_1_-1.png";
import profile3 from "image/XMLID_1_-2.png";
import profile4 from "image/XMLID_1_-3.png";
import profile5 from "image/XMLID_1_-4.png";
import profile6 from "image/XMLID_1_-5.png";
import profile7 from "image/XMLID_1_-6.png";
import profile8 from "image/XMLID_1_-7.png";
import profile9 from "image/XMLID_1_-8.png";
import profile10 from "image/XMLID_1_-9.png";
import profile11 from "image/XMLID_1_-10.png";
import {RevealOnScroll} from "components/RevealOnScroll";

interface IProps {
  image: string;
  name: string;
  position: string;
  linkedin: string;
}

const AboutUsBlock = ({image, name, position, linkedin}: IProps) => {
  return (
    <RevealOnScroll
      className={"grid grid-cols-12 col-span-1 border-solid border-[1px] border-[#EFEFEF] rounded-xl p-6"}
      animation={["opacity", "bottom"]}
    >
      <div className={"col-span-5 lg:col-span-4"}>
        <img
          src={image}
          alt={name}
          loading="lazy"
        />
      </div>
      <div className={"flex flex-col items-start gap-3 col-span-7 lg:col-span-8"}>
        <p className={"uppercase font-bold"}>{name}</p>
        <p className={"text-[#9993AA] text-sm"}>{position}</p>
        <a
          href={linkedin}
          className={"flex gap-2 bg-[#2C2C37] rounded py-1 px-2 justify-center items-center hover:bg-[#2C2C37]/70"}
        >
          <span className={"block text-white underline pl-2"}>Linkedin</span>
          <LinkIcon/>
        </a>
      </div>
    </RevealOnScroll>
  );
};


export const AboutUs = () => {
  return (
    <div
      id={"aboutUs"}
      className={"mobileWidth overflow-hidden"}
    >
      <RevealOnScroll
        animation={["opacity", "bottom"]}
      >
        <h2 className={"text-left mt-20 mb-8"}>Our Team</h2>
      </RevealOnScroll>
      <div className={"grid gap-y-6 gap-x-4 grid-cols-1 lg:grid-cols-3"}>
        <AboutUsBlock
          image={profile1}
          name={"Allan Young"}
          position={"CEO"}
          linkedin="https://www.linkedin.com/in/allantyoung/"
        />
        <AboutUsBlock
          image={profile2}
          name={"tim Suleymanov"}
          position={"Founder, Chairman"}
          linkedin="https://www.linkedin.com/in/timsuleymanov/"
        />
        <AboutUsBlock
          image={profile3}
          name={"Gary H.Baiton"}
          position={"Chief Operating Officer"}
          linkedin="https://www.linkedin.com/in/garyhbaiton/"
        />
        <AboutUsBlock
          image={profile4}
          name={"Bruno Bismuth"}
          position={"Chief Business Development"}
          linkedin="https://www.linkedin.com/in/brunobismuth/"
        />
        <AboutUsBlock
          image={profile5}
          name={"Oleksii Potapenko"}
          position={"Chief Creative"}
          linkedin="https://www.linkedin.com/in/oleksii-potapenko-589816136/"
        />
        <AboutUsBlock
          image={profile6}
          name={"Pavel Antohe"}
          position={"Chief User Acquisition"}
          linkedin="https://www.linkedin.com/in/pavelantohe/"
        />
        <AboutUsBlock
          image={profile7}
          name={"Pavel Zhdankin"}
          position={"Chief Retail"}
          linkedin="https://www.linkedin.com/in/pavel-zhdankin/"
        />
        <AboutUsBlock
          image={profile8}
          name={"IHOR MISHCHENKO"}
          position={"Head of Products"}
          linkedin="https://www.linkedin.com/in/ihor-mishchenko/"
        />
        <AboutUsBlock
          image={profile9}
          name={"Mikhail Kostinsky"}
          position={"Head of R&D"}
          linkedin="https://www.linkedin.com/in/mikhail-kostinsky/"
        />
      </div>
      <RevealOnScroll
        animation={["opacity", "bottom"]}
      >
        <h2 className={"text-left my-8"}>Advisors</h2>
      </RevealOnScroll>
      <div className={"grid gap-y-6 gap-x-4 grid-cols-1 lg:grid-cols-3"}>
        <AboutUsBlock
          image={profile10}
          name={"Erwan Camphuis"}
          position={"Board Member"}
          linkedin="https://www.linkedin.com/in/erwancamphuis/"
        />
        <AboutUsBlock
          image={profile11}
          name={"Luis Blanc"}
          position={"Senior Advisor"}
          linkedin="https://www.linkedin.com/in/luis-blanc/"
        />
      </div>
    </div>
  );
};