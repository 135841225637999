import React, {useEffect, useState} from "react";

import {CloseIcon} from "svg/CloseIcon";
import {Navigation} from "components/Navigation";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

export const Menu = ({isOpen, onClose}: IProps) => {
  const [menuClass, setMenuClass] = useState<string>("translate-x-full");

  useEffect(() => {
    setMenuClass("translate-x-0");

    return () => {
      setMenuClass("translate-x-full");
    };
  }, []);

  return (
    <div className={`inset-0 z-10 pl-12 ${isOpen ? "fixed" : "hidden"} lg:hidden`}>
      <div
        className={"absolute inset-0 bg-black/60 z-0"}
        onClick={onClose}
      />
      <div className={`relative bg-white h-full pl-10 transition duration-300 ease-in-out ${menuClass}`}>
        <div
          className={"flex justify-end pt-4 pb-12 pr-2"}
          onClick={onClose}
        >
          <CloseIcon/>
        </div>
        <Navigation
          className={"flex flex-col"}
          onClickLink={onClose}
        />
      </div>
    </div>
  );
};