import React from "react";

import chip from "image/chip.png";
import {RevealOnScroll} from "components/RevealOnScroll";

const AboutChipAndValuesBlock = ({title, description, delay}: {
  title: string,
  description: string,
  delay: number
}) => {
  return (
    <RevealOnScroll
      className={"col-span-1"}
      animation={["opacity", "bottom"]}
      delay={delay}
    >
      <p className={"text-2xl font-bold text-center lg:text-left"}>{title}</p>
      <p className={"text mt-3 lg:!text-left"}>{description}</p>
    </RevealOnScroll>
  );
};

export const AboutChipAndValues = () => {
  return (
    <div
      className={"bg-[#E8F2FC]/40 pt-16 lg:pt-32 pb-16 lg:px-20 lg:mt-20 overflow-hidden"}
      id={"about"}
    >
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <h2>The chip boasts its exclusive identification number and secure {"\"key\""} access, offering :</h2>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title mt-12 mb-4">
          extensive repository of product information, encompassing details regarding its origin, production lineage, and the current ownership.
        </p>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title">
          The compact chip withstands diverse temperatures and seamlessly imbeds to products.
        </p>
      </RevealOnScroll>
      <RevealOnScroll
        className={"flex justify-center mt-16"}
        animation={["opacity", "right"]}
      >
        <img
          src={chip}
          alt="chip"
          loading="lazy"
        />
      </RevealOnScroll>
      <RevealOnScroll
        className={"flex justify-center mt-6"}
        animation={["opacity", "left"]}
      >
        <ul className={"list-disc"}>
          <li className={"text-sm text-[#6E6F7D]"}>Package: 3.2mm x 3.2mm x 0.7mm</li>
          <li className={"text-sm text-[#6E6F7D]"}>Operating Temperature Range: - 40°C to + 85°C</li>
        </ul>
      </RevealOnScroll>
      <div className={"grid gap-4 grid-cols-1 lg:grid-cols-3 mt-20"}>
        <AboutChipAndValuesBlock
          title={"Countermeasure"}
          description={"The distinctiveness of a digital certificate and the digital ownership code for physical products safeguarded by blockchain."}
          delay={300}
        />
        <AboutChipAndValuesBlock
          title={"Transparency"}
          description={"We deliver services with exceptional efficiency and adherence to industry standards."}
          delay={300}
        />
        <AboutChipAndValuesBlock
          title={"Innovation"}
          description={"This technology is pilot-ready and continually tested, updated, and measured with various retail products."}
          delay={300}
        />
        <AboutChipAndValuesBlock
          title={"Availability"}
          description={"The NFCHIP Physical Blockchain solution enables brands to significantly reduce counterfeiting in both luxury and mass markets."}
          delay={600}
        />
        <AboutChipAndValuesBlock
          title={"Control"}
          description={"Our foremost priorities encompass systematization and the assurance of uptime guarantees."}
          delay={600}
        />
      </div>
    </div>
  );
};