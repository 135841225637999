import React from "react";

import {ISvg} from "svg/Icon";

interface IProps extends ISvg {

}

export const LinkIcon = ({size = 16}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41665 2L11.612 4.19533L6.94531 8.862L7.88798 9.80467L12.5546 5.138L14.75 7.33333V2H9.41665Z"
        fill="#4F80FF"
      />
      <path
        d="M13.4167 12.6667H4.08333V3.33333H8.75L7.41667 2H4.08333C3.348 2 2.75 2.598 2.75 3.33333V12.6667C2.75 13.402 3.348 14 4.08333 14H13.4167C14.152 14 14.75 13.402 14.75 12.6667V9.33333L13.4167 8V12.6667Z"
        fill="#4F80FF"
      />
    </svg>
  );
};