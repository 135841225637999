import React from "react";

import {NewTechnology} from "context/Home/NewTechnology";
import {WhyNFCHIP} from "context/Home/WhyNFCHIP";
import {AboutChipAndValues} from "context/Home/AboutChipAndValues";
import {App} from "context/Home/App";
import {AboutUs} from "context/Home/AboutUs";
import {Title} from "context/Home/Title";
import {RevealOnScroll} from "components/RevealOnScroll";

export const Home = () => {
  return (
    <main className={"grid gap-8 w-full max-w-[1440px] m-auto px-4"}>
      <Title/>
      <RevealOnScroll>
        <NewTechnology/>
      </RevealOnScroll>
      <RevealOnScroll>
        <WhyNFCHIP/>
      </RevealOnScroll>
      <RevealOnScroll className={"relative"}>
        <div className={"absolute bg-[#E8F2FC]/40 inset-0 z-0 w-[100vw] -left-4 lg:hidden"}/>
        <AboutChipAndValues/>
      </RevealOnScroll>
      <RevealOnScroll>
        <App/>
      </RevealOnScroll>
      <RevealOnScroll>
        <AboutUs/>
      </RevealOnScroll>
    </main>
  );
};