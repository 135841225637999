import React from "react";

import title from "image/title.png";
import {RevealOnScroll} from "components/RevealOnScroll";

export const Title = () => {
  return (
    <section className={"mt-14 lg:mt-28"}>
      <div className={"flex gap-4 relative h-[400px] lg:h-[600px] rounded-2xl overflow-hidden before:content-[''] before:absolute before:inset-0 before:opacity-70 before:bg-gradient-to-r before:from-[#011B38] before:to-[#F7F7F9] before:-z-1 flex-col lg:flex-row"}>
        <RevealOnScroll
          className={"relative flex flex-1 gap-2 lg:gap-4 flex-col justify-center px-8"}
          animation={["opacity", "right"]}
        >
          <span className={"text-white text-2xl mt-2 drop-shadow-2xl text-center lg:text-4xl lg:text-left lg:mt-0"}>First Non-Fungible Chip:</span>
          <span className={"text-white drop-shadow-2xl text-center lg:text-xl lg:text-left"}>Elevate Value, Validate Authenticity – </span>
          <span className={"text-white drop-shadow-2xl text-center lg:text-xl lg:text-left"}>Trust with One Scan.</span>
        </RevealOnScroll>
        <RevealOnScroll
          className={"flex flex-1 justify-center items-center"}
          animation={["opacity", "left"]}
        >
          <img
            src={title}
            alt="chip"
            loading="lazy"
          />
        </RevealOnScroll>
      </div>
    </section>
  );
};