import React from "react";

import {RevealOnScroll} from "components/RevealOnScroll";

interface IProps {
  img: string;
  title: string;
  text: string;
  duration: number;
  delay: number;
}

export const NewTechnologyBlock = ({img, title, text, duration, delay}: IProps) => {
  return (
    <RevealOnScroll
      animation={["opacity", "bottom"]}
      duration={duration}
      delay={delay}
    >
      <div className={"relative grid gap-4 col-span-1 rounded-2xl justify-items-center h-[90%] before:content-[''] before:absolute before:inset-0 before:bg-gradient-to-r before:from-[#D6E7E7] before:to-[#F3F3F3] before:-z-1 before:rounded-2xl [&>*]:-translate-y-12"}>
        <img
          className={"relative"}
          src={img}
          alt={title}
          loading="lazy"
        />
        <p className={"relative title px-4"}>{title}</p>
        <p className={"relative text px-4"}>{text}</p>
      </div>
    </RevealOnScroll>
  );
};