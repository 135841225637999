import React from "react";

import nfchip from "image/nfchip.png";
import {RevealOnScroll} from "components/RevealOnScroll";

export const WhyNFCHIP = () => {
  return (
    <div
      id={"whyNfchip"}
      className={"mt-20 overflow-hidden mobileWidth"}
    >
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <h2 className={"text-left mb-12 lg:mb-20"}>Why NFCHIP Stands Above the Rest</h2>
      </RevealOnScroll>

      <div className={"grid gap-4 grid-cols-1 lg:grid-cols-2"}>
        <RevealOnScroll
          className={"col-span-1"}
          animation={["opacity", "right"]}
        >
          <p className="title mb-8 !text-left">
            The NFCHIP solution represents a transformative opportunity for brands, offering a comprehensive solution to address the pervasive challenges in the buying and selling process. With this innovative system, brands can seamlessly transition to a novel product pricing model founded on the principle of indisputable product ownership, fortified by the pinnacle of cutting-edge, impervious blockchain technology.
          </p>
          <p className="title mb-8 !text-left">
            Consider owning premium sneakers or an exclusive timepiece, each endowed with a digital certificate that not only verifies their authenticity but also confirms your rightful ownership, all without the encumbrance of in-store verifications.
          </p>
          <p className="title mb-8 !text-left">
            However, NFCHIP`s prowess extends well beyond the confines of fashion. From the preservation of invaluable artworks to the meticulous stewardship of high-end wine collections, from the meticulous oversight of precision-engineered industrial products to the secure safeguarding of mission-critical asset documents, NFCHIP`s Physical Blockchain technology stands as the epitome of scalability, effortlessly traversing the spectrum of luxury and mass-market industries.
          </p>
          <p className="title !text-left">
            In essence, NFCHIP is not merely a solution but a herald of a new epoch in commerce. It has the potential to redefine how businesses and consumers interact with products, promising an era characterized by unparalleled trust, unassailable transparency, and a fortified supply chain that spans the entirety of economic pursuits. NFCHIP, the hallmark of a retail revolution.
          </p>
        </RevealOnScroll>
        <RevealOnScroll
          className={"flex col-span-1 items-center"}
          animation={["opacity", "left"]}
        >
          <img
            src={nfchip}
            alt="nfchip"
            loading="lazy"
          />
        </RevealOnScroll>
      </div>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className={"text-xl mt-16"}>We offer a comprehensive authentication and ownership verification solution for <strong>brands, manufacturers, and consumers, ensuring complete trust and transparency.</strong>
        </p>
      </RevealOnScroll>
    </div>
  );
};