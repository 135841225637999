import React, {ReactNode, useEffect, useMemo, useRef, useState} from "react";

type TAnimation = "opacity" | "left" | "right" | "top" | "bottom";

interface IProps {
  className?: string;
  animation?: TAnimation[];
  duration?: number;
  delay?: number;
  children: ReactNode;
}

export const RevealOnScroll = ({
  className = "",
  animation = ["opacity"],
  duration = 2000,
  delay = 0,
  children
}: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const scrollObserver = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        scrollObserver.unobserve(entry.target);
      }
    });

    scrollObserver.observe(ref.current!);

    return () => {
      if (ref.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollObserver.unobserve(ref?.current);
      }
    };
  }, []);

  const animationList = useMemo(() => {
    let classIn = "";
    let classOut = "";

    animation.map((el) => {
      switch (el) {
        case "opacity": {
          classIn += " opacity-0";
          classOut += " opacity-100";
        }
          break;
        case "left": {
          classIn += " -translate-x-full";
          classOut += " translate-x-0";
        }
          break;
        case "right": {
          classIn += " translate-x-full";
          classOut += " translate-x-0";
        }
          break;
        case "top": {
          classIn += " -translate-y-full";
          classOut += " translate-y-0";
        }
          break;
        case "bottom": {
          classIn += " translate-y-full";
          classOut += " translate-y-0";
        }
          break;
      }
    }, []);

    return {in: classIn, out: classOut};
  }, [animation]);

  return (
    <div
      ref={ref}
      className={`${className} transition ${isVisible ? animationList.out : animationList.in}`}
      style={{
        transitionDuration: `${duration}ms`,
        transitionDelay: `${delay}ms`
      }}
    >
      {children}
    </div>
  );
};