import React from "react";

import Apple from "image/img.png";

interface IProps {
  className: string;
  onClickLink: () => void;
}

export const Navigation = ({className, onClickLink}: IProps) => {
  const navigateTo = (name: string) => {
    const element = document.getElementById(name);

    if (element) {
      window.scrollTo({
        top: (element.getBoundingClientRect().top + document.documentElement.scrollTop) - 100,
        behavior: "smooth"
      });
    }

    onClickLink();
  };

  return (
    <nav className={`gap-8 lg:gap-16 justify-end items-start lg:items-center ${className}`}>
      <ul className={"flex gap-4 list-disc lg:list-none flex-col lg:flex-row"}>
        <li
          className={"cursor-pointer hover:underline"}
          onClick={() => navigateTo("newTechnology")}
        >NEW TECHNOLOGY
        </li>
        <li
          className={"cursor-pointer hover:underline"}
          onClick={() => navigateTo("whyNfchip")}
        >WHY NFCHIP
        </li>
        <li
          className={"cursor-pointer hover:underline"}
          onClick={() => navigateTo("about")}
        >ABOUT CHIP & VALUES
        </li>
        <li
          className={"cursor-pointer hover:underline"}
          onClick={() => navigateTo("app")}
        >APP
        </li>
        <li
          className={"cursor-pointer hover:underline"}
          onClick={() => navigateTo("aboutUs")}
        >ABOUT US
        </li>
      </ul>
      <a href="#">
        <img
          className={"max-w-[128px] -ml-6 lg:ml-0"}
          src={Apple}
          alt="apple"
          loading="lazy"
        />
      </a>
    </nav>
  );
};