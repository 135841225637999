import React from "react";

export const Footer = () => {
  return (
    <div className={"flex gap-4 justify-between py-4 px-2 w-full flex-col lg:flex-row"}>
      <p className={"text-center lg:text-left"}>©nfchip.io {new Date().getFullYear()}. All rights reserved</p>
      <p className={"text-center lg:text-left"}>logos or names of respected brands are used for illustrative or reference purposes only, with no affiliation with these brands.</p>
      <p className={"text-center lg:text-left"}>E-mail: <a
        className={"text-[#4F80FF]"}
        href="mailto:sales@nfchip.ai"
      >sales@nfchip.ai</a></p>
    </div>
  );
};