import React from "react";

import phone1 from "image/phone_1.png";
import phone2 from "image/phone_2.png";
import phone3 from "image/phone_3.png";
import phone4 from "image/phone_4.png";
import phone5 from "image/phone_5.png";
import {RevealOnScroll} from "components/RevealOnScroll";

export const App = () => {
  return (
    <div id={"app"}>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <h2 className={"mt-20"}>This portfolio includes verified items and access to a marketplace for expanding their collection.</h2>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title mt-16">
          Integrating AI into the NFChip ecosystem represents a forward-looking strategy aimed at enhancing the value and functionality of our innovative solution. For brands, AI provides the power to bolster brand protection and authentication continually. Machine learning algorithms enable NFChip to adapt to evolving counterfeit tactics, empowering brands with real-time insights and proactive alerts to combat fraudulent products effectively.
        </p>
        <p className="title mt-4">
          Moreover, AI-driven data analysis grants brands invaluable consumer insights and market trends, enabling data-driven decision-making.
        </p>
      </RevealOnScroll>
      <div className={"flex gap-5 mt-20 overflow-y-hidden overflow-x-auto lg:justify-center mobileWidth lg:overflow-x-hidden"}>
        <RevealOnScroll
          className={"min-w-[250px]"}
          animation={["opacity", "bottom"]}
        >
          <img
            className={"transition lg:hover:scale-110"}
            src={phone1}
            alt="image"
            loading="lazy"
          />
        </RevealOnScroll>
        <RevealOnScroll
          className={"min-w-[250px]"}
          animation={["opacity", "bottom"]}
          delay={200}
        >
          <img
            className={"transition lg:hover:scale-110"}
            src={phone2}
            alt="image"
            loading="lazy"
          />
        </RevealOnScroll>
        <RevealOnScroll
          className={"min-w-[250px]"}
          animation={["opacity", "bottom"]}
          delay={400}
        >
          <img
            className={"transition lg:hover:scale-110"}
            src={phone3}
            alt="image"
            loading="lazy"
          />
        </RevealOnScroll>
        <RevealOnScroll
          className={"min-w-[250px]"}
          animation={["opacity", "bottom"]}
          delay={600}
        >
          <img
            className={"transition lg:hover:scale-110"}
            src={phone4}
            alt="image"
            loading="lazy"
          />
        </RevealOnScroll>
        <RevealOnScroll
          className={"min-w-[250px]"}
          animation={["opacity", "bottom"]}
          delay={800}
        >
          <img
            className={"transition lg:hover:scale-110"}
            src={phone5}
            alt="image"
            loading="lazy"
          />
        </RevealOnScroll>
      </div>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title !font-normal mt-20">
          For consumers, AI integration elevates the user experience through personalized features. Our AI-powered mobile app tailors recommendations based on individual purchase history and preferences, fostering brand loyalty. Additionally, AI streamlines the user interface, simplifying interactions with the NFChip for effortless product authentication and comprehensive information access.
        </p>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title !font-normal mt-4">
          This strategic fusion of AI and NFChip ensures top-tier security and authenticity.
        </p>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title !font-normal mt-4">
          At <strong>Nfchip.ai</strong> our distinguished team comprises elite chip designers and software architects with roots in Silicon Valley and key tech hubs worldwide. Our forte lies in meticulously crafting user experiences of exceptional quality, meticulously tailored for the most discerning customers and esteemed brand partners.
        </p>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className="title !font-normal mt-4">
          With a combined wealth of experience spanning over two decades, we have refined our art in engineering hardware and software solutions that transcend customer expectations. Over the past four years, our unwavering dedication has been directed towards conceptualizing and engineering the forthcoming frontier of consumer products, deftly bridging the convergence of blockchain technology and the universe of Digital Ownership Titles (DOTs).
        </p>
      </RevealOnScroll>
    </div>
  );
};