import React, {useState} from "react";

import {MenuIcon} from "svg/MenuIcon";
import {Navigation} from "components/Navigation";
import {Menu} from "components/Menu";

export const Header = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <header className={"fixed bg-white w-full m-auto py-4 px-4 lg:py-8 z-[2]"}>
        <MenuIcon
          className={"ml-auto relative lg:hidden"}
          onClick={() => setIsOpen(true)}
        />
        <Navigation
          className={"hidden lg:flex"}
          onClickLink={() => setIsOpen(false)}
        />
      </header>
      {isOpen &&
        <Menu
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      }
    </>
  );
};