import React from "react";

import {NewTechnologyBlock} from "context/Home/NewTechnologyBlock";
import img1 from "image/new_1.png";
import img2 from "image/new_2.png";
import img3 from "image/new_3.png";
import {RevealOnScroll} from "components/RevealOnScroll";

export const NewTechnology = () => {
  return (
    <div id={"newTechnology"}>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <h2 className={"mb-8"}>At the Vanguard of Fashion-Tech Innovation</h2>
      </RevealOnScroll>
      <RevealOnScroll animation={["opacity", "bottom"]}>
        <p className={"title"}>
          Unveiling our Physical Non-Fungible Chip — a Defender Against Counterfeits in Luxury and Mass Market Retail. Elevate Value with NFChip:
        </p>
        <p className={"title"}>Attach to any item for a Unique, Immutable Digital Certificate.</p>
      </RevealOnScroll>
      <div className={"grid gap-4 grid-cols-1 lg:grid-cols-3 mt-20"}>
        <NewTechnologyBlock
          img={img1}
          title={"Safeguard Product Integrity with Confidence"}
          text={"Our technology provides discerning buyers with a digital certificate, ensuring originality and authenticity, fortifying brand products against the counterfeit market."}
          duration={2000}
          delay={0}
        />
        <NewTechnologyBlock
          img={img2}
          title={"Amplify Your Product's Worth"}
          text={"Retail enters a groundbreaking product pricing era with OWNERSHIP, driven by state-of-the-art and secure Blockchain technology."}
          duration={2000}
          delay={300}
        />
        <NewTechnologyBlock
          img={img3}
          title={"Seamless Access to Comprehensive Information"}
          text={"With NFChip's smartphone application, effortlessly retrieve data from the physical chip and view it on your interface. Gain a holistic understanding of the product and its authenticity."}
          duration={2000}
          delay={600}
        />
      </div>
    </div>
  );
};