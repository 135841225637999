import React from "react";

import {ISvg} from "svg/Icon";

interface IProps extends ISvg {

}

export const CloseIcon = ({size = 16, onClick}: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M0.44728 0.44728C0.733759 0.160887 1.12226 0 1.52734 0C1.93242 0 2.32092 0.160887 2.6074 0.44728L8.00922 5.8491L13.411 0.44728C13.6992 0.169003 14.0851 0.015023 14.4856 0.0185037C14.8862 0.0219843 15.2693 0.182647 15.5526 0.465889C15.8358 0.74913 15.9965 1.13229 15.9999 1.53284C16.0034 1.93339 15.8494 2.31928 15.5712 2.6074L10.1693 8.00922L15.5712 13.411C15.8494 13.6992 16.0034 14.0851 15.9999 14.4856C15.9965 14.8862 15.8358 15.2693 15.5526 15.5526C15.2693 15.8358 14.8862 15.9965 14.4856 15.9999C14.0851 16.0034 13.6992 15.8494 13.411 15.5712L8.00922 10.1693L2.6074 15.5712C2.31928 15.8494 1.93339 16.0034 1.53284 15.9999C1.13229 15.9965 0.74913 15.8358 0.465889 15.5526C0.182647 15.2693 0.0219843 14.8862 0.0185037 14.4856C0.015023 14.0851 0.169003 13.6992 0.44728 13.411L5.8491 8.00922L0.44728 2.6074C0.160887 2.32092 0 1.93242 0 1.52734C0 1.12226 0.160887 0.733759 0.44728 0.44728Z"
        fill="black"
      />
    </svg>
  );
};